import React from 'react';
import art from './Assets/Images/AboutPage/Charity art.png';
import fourth from './Assets/Images/AboutPage/charity.svg';
import charr from './Assets/Images/AboutPage/charr.svg';
import last from './Assets/Images/AboutPage/lasttt.svg';
import secondc from './Assets/Images/AboutPage/secondc.svg';
import third from './Assets/Images/AboutPage/third.svg';
import top from './Assets/Images/AboutPage/top.png';
import t from './Assets/Images/AboutPage/textt.png'
import { houston_charity, sfjc_logo_charity } from './Assets/Images/Index';
import Footer from './Footer';
import NavBar from './NavBar';
import './Styles/Charity.css';
import YouTube from 'react-youtube';
import { NavLink } from 'react-router-dom';

var getYouTubeID = require('get-youtube-id');

const Charity = () => {
  const url = getYouTubeID('https://youtu.be/BVidWEPGv2I');
  const opts = {
    height: '348px',
    width: '692px',
    playerVars: {
      autoplay: 0,
    },
  };

  return (
    <div className='about-page'>
      <NavBar />
      <br />

      <p className='text-center' style={{marginTop:60}}>
        <img className='text-center' src={top} alt='charity'></img>
        <br />
        <br />
        <br />
      </p>

      <div className='charity_img-vid'>
        <NavLink
          className='charity_img_nav-link'
          to='http://safefjc.org'
          target='_blank'
        >
          <div className='charity_img'>
            <img src={sfjc_logo_charity} alt='sfjc_logo_charity' />
            <p className='charity_img_link'>http://safefjc.org</p>
            
          </div>
        </NavLink>
        <div className='charity_vid'>
          <YouTube videoId={url} opts={opts} />
        </div>
      </div>
      <div className='text-center' style={{marginBottom:50,marginLeft:400}}>
        <img src={t} alt="no"></img>
      </div>

      <div
        style={{ flexDirection: 'row', justifyContent: 'space-between' }}
        className='row text-center'
      >
        <div className='col-6' style={{ margin: '0' }}>
          {' '}
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <img className='text-center' src={charr} alt='charity'></img> <br />
          <br />
          <br />
          <br />
          <br />
          <a href='https://www.homeless-healthcare.org/'>
            <img src={houston_charity} alt={houston_charity} />{' '}
          </a>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <a href='https://www.selahnhc.org/'>
            <img className='text-center' src={secondc} alt='charity'></img>
          </a>
          <br />
          <br />
          <br />
          <br />
          <br />
          <a href='https://nphy.org/'>
            <img className='text-center' src={third} alt='charity'></img>
          </a>
          <br />
          <br />
          <br />
          <br />
          <br />
          <a href='https://endhomelessness.org/'>
            <img className='text-center' src={fourth} alt='charity'></img>
          </a>
          <br />
          <br />
          <br />
          <br />
          <br />
          <a href='https://www.streetwise.org/'>
            <img className='text-center' src={last} alt='charity'></img>
          </a>
          <br />
          <br />
          <br />
          <br />
          <br />
        </div>

        <div style={{ textAlign: 'right', width: '50%', padding: '0' }}>
          <img
            src={art}
            alt='charity'
            style={{ width: '799.4px', height: '1998.47px' }}
          />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Charity;
