import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  home,
  home_dark,
  logo_ai,
  text_how_to_use,
} from './Assets/Images/Index';
import SpinTokens from './SpinTokens';
import './Styles/HowToUse.css';

const HowToUse = () => {
  const navigate = useNavigate();

  return (
    <div className='how'>
      <SpinTokens
        tp='0'
        bt='560px'
        le='0'
        ri='910px'
        ht='82.29px'
        ht_dr='82.29px'
        src={home}
        src_dr={home_dark}
        name='home'
        name_dr='home_dr'
        link='/'
      />
      <img
        style={{ width: '235px', height: '57.89px', marginTop: '50px' }}
        src={logo_ai}
        alt='logo_ai'
      />
      <p className='how_build_desc'>Build your SoRekt Ai NFT</p>
      <img
        src={text_how_to_use}
        style={{ marginTop: '60px', marginRight: '330px' }}
        alt='text_how_to_use'
      />
      <div className='how_back_btn' onClick={() => navigate('/inspression')}>
        Go back
      </div>
    </div>
  );
};

export default HowToUse;
