import React from 'react';
import './Styles/ClubPage.css';
import NavBar from './NavBar';

import club from './Assets/Images/AboutPage/club.svg';

import Footer from './Footer';
import {
  blog_club,
  blog_img_1,
  blog_img_2,
  blog_img_3,
  r1_c2_club,
  r2_c2_club,
  r3_c2_club,
  vlog_img_club,
  vlog_vid_1,
  vlog_vid_2,
  vlog_vid_3,
} from './Assets/Images/Index';

const Club = () => {
  return (
    <div className='club-page'>
      <NavBar />
      <br />
      <br />
      <br />
      <p className='text-center'>
        <img src={club} alt='group' className='text-center'></img>
      </p>
      <div className='club-page_blog'>
        <img src={blog_club} alt='blog_club' />
        <div className='club-page_blogs'>
          <div className='club-page_blogs_indi'>
            <div className='club-page_blogs_indi_c1'>
              <img src={blog_img_1} alt='blog_img_1' />
            </div>
            <div className='club-page_blog_indi_c2'>
              <img src={r1_c2_club} alt='r1_c2_club' />
            </div>
          </div>
          <div className='club-page_blogs_indi'>
            <div className='club-page_blogs_indi_c1'>
              <img src={blog_img_2} alt='blog_img_2' />
            </div>
            <div className='club-page_blogs_indi_c2'>
              <img src={r2_c2_club} alt='r1_c2_club' />
            </div>
          </div>
          <div className='club-page_blogs_indi'>
            <div className='club-page_blogs_indi_c1'>
              <img src={blog_img_3} alt='blog_img_3' />
            </div>
            <div className='club-page_blogs_indi_c2'>
              <img src={r3_c2_club} alt='r1_c2_club' />
            </div>
          </div>
        </div>
      </div>
      <div className='club-page_vlog'>
        <img src={vlog_img_club} alt='vlog_img_club' />
        <div className='club-page_vlogs'>
          <div className='club-page_vlogs_indi'>
            <a
              href='https://www.youtube.com/watch?v=0DWJ0EQB-Q4'
              rel='noreferrer'
              target='_blank'
            >
              <img
                className='club-page_vlogs_indi_img'
                src={vlog_vid_1}
                alt='vlog_vid_1'
              />
            </a>
            <p className='club-page_vlogs_indi_txt'>What is SoRekt NFT?</p>
          </div>
          <div className='club-page_vlogs_indi'>
            <a
              href='https://youtu.be/uqV49tnEwAM'
              rel='noreferrer'
              target='_blank'
            >
              <img
                className='club-page_vlogs_indi_img'
                src={vlog_vid_2}
                alt='vlog_vid_2'
              />
            </a>
            <p className='club-page_vlogs_indi_txt'>
              What makes SoRekt different?
            </p>
          </div>
          <div className='club-page_vlogs_indi'>
            <a
              href='https://www.youtube.com/watch?v=tt0xR3J997I'
              rel='noreferrer'
              target='_blank'
            >
              <img
                className='club-page_vlogs_indi_img'
                src={vlog_vid_3}
                alt='vlog_vid_3'
              />
            </a>
            <p className='club-page_vlogs_indi_txt'>How to buy SoRekt token?</p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Club;
