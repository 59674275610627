import React from 'react';
import './Styles/Footer.css';
import {
  footer_bgd,
  discord_footer,
  twitter_footer,
  yt_footer,
  boat_footer,
  me_footer,
  text_info_footer,
  twitter_footer_dark,
  discord_footer_dark,
  boat_footer_dark,
  me_footer_dark,
  yt_footer_dark,
} from './Assets/Images/Index';
import { SoRekt_Black_1 } from './Assets/Images/Index';
import { NavLink } from 'react-router-dom';

const Footer = () => {
  return (
    <div className='footer'>
      <div className='footer__img-social'>
        <div>
          <div className='footer__img-social__img'>
            <img
              class='footer__img-social__img__img'
              src={SoRekt_Black_1}
              alt='SoRekt_Black_1'
            />
            <div className='footer__img-social__img__text'>
              <img src={text_info_footer} alt='footer_text' />
            </div>
          </div>
        </div>
        <div className='footer__img-social__social'>
          <div className='footer__img-social_div'>
            <NavLink to='https://twitter.com/sorektNFT' target='_blank'>
              <img src={twitter_footer} alt='twitter_footer' />
              <img
                className='footer_img-social_social_hover'
                src={twitter_footer_dark}
                alt='twitter_footer_dark'
              />
            </NavLink>
          </div>
          <div className='footer__img-social_div'>
            <NavLink to='https://discord.gg/y2BHK7nwyc' target='_blank'>
              <img src={discord_footer} alt='discord_footer' />
              <img
                className='footer_img-social_social_hover'
                src={discord_footer_dark}
                alt='discord_footer_dark'
              />
            </NavLink>
          </div>
          <div className='footer__img-social_div'>
            <NavLink
              to='https://opensea.io/collection/sorekt-wtf'
              target='_blank'
            >
              <img src={boat_footer} alt='discord_footer' />
              <img
                className='footer_img-social_social_hover'
                src={boat_footer_dark}
                alt='boat_footer_dark'
              />
            </NavLink>
          </div>
          <div className='footer__img-social_div'>
            <NavLink to='/'>
              <img src={me_footer} alt='me_footer' />
              <img
                className='footer_img-social_social_hover'
                src={me_footer_dark}
                alt='me_footer_dark'
              />
            </NavLink>
          </div>
          <div className='footer__img-social_div'>
            <NavLink to='/'>
              <img src={yt_footer} alt='yt_footer' />
              <img
                className='footer_img-social_social_hover'
                src={yt_footer_dark}
                alt='yt_footer_dark'
              />
            </NavLink>
          </div>
        </div>
      </div>
      <div className='footer__img_div'>
        <img src={footer_bgd} alt='footer_bgd' class='footer__img' />
      </div>
      <p className='footer_rights'>All Rights Reserved 2023</p>
    </div>
  );
};

export default Footer;
