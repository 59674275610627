import React from 'react';
import './Styles/AboutPage.css';
import NavBar from './NavBar';
import {
  about_1,
  man_blink,
  what_is_1,
  back_2_for_yt_1,
  offers_light_about,
  offers_dark_about,
} from './Assets/Images/Index';
import YouTube from 'react-youtube';
import Footer from './Footer';
import Teamimg from './Assets/Images/AboutPage/team.png';
import team from './Assets/Images/AboutPage/team.svg';
import team2 from './Assets/Images/AboutPage/team2.svg';
import { NavLink } from 'react-router-dom';

var getYouTubeID = require('get-youtube-id');

const AboutPage = () => {
  const url = getYouTubeID('https://www.youtube.com/watch?v=uqV49tnEwAM');
  const opts = {
    height: '390',
    width: '670',
    playerVars: {
      autoplay: 0,
    },
  };
  return (
    <div className='about-page'>
      <NavBar />
      <img src={about_1} alt='about' className='about-page__about' />
      <img src={man_blink} alt='man_blink' className='about-page__man-blink' />
      <NavLink to='/buy-nft-offers'>
        <div className='about-page_ads'>
          <img
            className='about-page_ads_light'
            src={offers_light_about}
            alt='offers_light_about'
          />
          <img
            className='about-page_ads_dark'
            src={offers_dark_about}
            alt='offers_dark_about'
          />
        </div>
      </NavLink>
      <img
        src={what_is_1}
        alt='what_is_1'
        className='about-page__what-sorekt'
      />
      <p className='about-page__explain'>
        Introducing SoRekt: The NFT that Everyone Can Relate To <br /> In these
        tumultuous times, the crypto market and global economy have been hit
        hard. Many of us have felt the effects of the crash, experiencing
        <br />
        feelings of being "wrecked" by the volatility and uncertainty. But what
        if there was a way to not only acknowledge these struggles, but also
        find a<br /> sense of hope and resilience in them? That's where SoRekt
        comes in.
        <br /> SoRekt is an NFT that perfectly captures the essence of the
        crypto crash and the challenges of the current economy. The art piece,
        created by
        <br /> talented digital artist, not only represents the struggle, but
        also serves as a reminder that even in the darkest of times, there is
        always the
        <br /> possibility for resurgence.
        <br /> But SoRekt is not just an NFT, it's a symbol of hope and a
        community for those who have been affected by the recent events in the
        crypto and <br />
        economy world. That's why we are excited to announce the launch of
        SoRekt token, a memecoin like Dogecoin that will serve as a way for the
        <br /> community to come together and support one another. The token
        will be available on various crypto exchanges.
        <br /> SoRekt NFT will be available for purchase on Magic Eden and
        OpenSea at a price of 99$ per NFT, making it accessible for everyone.
        This is a<br /> limited edition NFT, and it's a great opportunity for
        art and crypto enthusiasts to own a piece of digital art that represents
        a moment in history.
        <br /> In conclusion, SoRekt is not just an NFT, it's a symbol of hope
        and resilience in the face of adversity. It represents the struggles
        we've all faced,
        <br /> but also serves as a reminder that together, we can rise from the
        wreckage. Join the community and grab your SoRekt NFT today!
      </p>
      <div className='about-page__video'>
        <img
          className='about-page__video__img'
          src={back_2_for_yt_1}
          alt='back_2_for_yt_1'
        />
        <YouTube videoId={url} opts={opts} />
      </div>
      <img src={Teamimg} alt='about' className='about-page__about' />
      <img src={man_blink} alt='man_blink' className='about-page__man-blink' />
      <br />
      <p className='text-center'>
        <img src={team} alt='group' className='text-center'></img>
        <br />
        <br />
        <br />
        <img src={team2} alt='group' className='text-center'></img>
      </p>
      <Footer />
    </div>
  );
};

export default AboutPage;
