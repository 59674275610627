import React from 'react';
import './Styles/Team.css';
import NavBar from './NavBar';

import rulebook from './Assets/Images/AboutPage/rulebook.svg';

import Footer from './Footer';

const Rulebook = () => {
  return (
    <div className='about-page'>
      <NavBar />
      <br />
      <br />
      <br />
      <p className='text-center'>
        <img src={rulebook} alt='group' className='text-center'></img>
      </p>

      <Footer />
    </div>
  );
};

export default Rulebook;
