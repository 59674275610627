import React, { useState } from 'react';
import {
  bulletin_head,
  bulletin_nft,
  bulletin_nft_1,
  bulletin_nft_2,
  bulletin_nft_3,
  bulletin_nft_4,
  meme,
  bulletin_nft_dark,
  meme_dark_ai,
  story,
  story_dark,
  bulletin_meme_1,
  bulletin_meme_2,
  bulletin_meme_3,
  bulletin_meme_4,
  bulletin_meme_5,
  bulletin_dog,
  bulletin_darth,
  bulletin_mask,
} from './Assets/Images/Index';
import NavBar from './NavBar';
import './Styles/BulletinPage.css';
import RoundButton from './RoundButton';
import ImageCreators from './ImageCreators';

const BulletinPage = () => {
  const [subPage, setSubPage] = useState('story');
  const nftImages = [
    {
      src: bulletin_nft_1,
      creator: 'Thomas',
    },
    {
      src: bulletin_nft_2,
      creator: 'Kim',
    },
    {
      src: bulletin_nft_3,
      creator: 'Windy',
    },
    {
      src: bulletin_nft_4,
      creator: 'Sam',
    },
  ];
  const memeImages = [
    {
      src: bulletin_meme_1,
      creator: 'Sam',
    },
    {
      src: bulletin_meme_2,
      creator: 'Whamos',
    },
    {
      src: bulletin_meme_3,
      creator: 'Jr',
    },
    {
      src: bulletin_meme_4,
      creator: 'Stew',
    },
    {
      src: bulletin_meme_5,
      creator: 'mike',
    },
  ];

  let child;
  if (subPage === 'nft') {
    child = (
      <div className='bulletin_nft'>
        <div className='bulletin_nft_inner'>
          {nftImages.map((imageData) => (
            <ImageCreators imageData={imageData} key={imageData.creator} />
          ))}
        </div>
      </div>
    );
  } else if (subPage === 'meme') {
    child = (
      <div className='bulletin_meme'>
        <div className='bulletin_meme_inner'>
          {memeImages.map((imageData) => (
            <ImageCreators imageData={imageData} key={imageData.creator} />
          ))}
        </div>
      </div>
    );
  } else if (subPage === 'story') {
    child = (
      <div className='bulletin_story'>
        <div className='bulletin_story_margin'>
          <div className='bulletin_story_row'>
            <img
              style={{ width: '50px', height: '50px' }}
              src={bulletin_dog}
              alt='bulletin_dog'
            />
            <div className='bulletin_story_row_text'>
              <p>
                The story of "rekt" as an internet slang term is one of
                evolution and adaptation. It is believed to have originated in
                gaming communities, specifically in the first person shooter
                communities, as a shorthand way of describing a devastating
                defeat or humiliation. The term quickly spread to other online
                communities, such as forums, social media, and chat rooms, where
                it was used in a similar way to describe a wide range of
                devastating events or setbacks. As the term gained popularity,
                it began to take on new meanings and connotations. Today, "rekt"
                is often used as a playful or exaggerated way to describe a
                minor setback or inconvenience, as well as a more serious and
                dramatic way to describe a major defeat or failure.
              </p>
              <p className='bulletin_story_r1_creator'>- SoRen-</p>
            </div>
          </div>
          <div className='bulletin_story_row'>
            <img
              style={{ width: '50px', height: '50px' }}
              src={bulletin_darth}
              alt='bulletin_darth'
            />
            <div className='bulletin_story_row_text'>
              <p>
                <b>Slim:</b> SOREKT-SICK-REKT! <br />
                <b>Rasta:</b> CRYPTO-WILD-WAT!
                <br />
                <b>Slim:</b> REKT-WORLD-GOING-HAM!
                <br /> <b>Rasta:</b> CRYPTO-LIT-AF-TOO!
                <br />
                <b>Slim:</b> SOREKT-ILL-AS-HELL! <br />
                <b>Rasta:</b> CRYPTO-THE-BOMB-DOT-COM!
                <br />
                <b>Slim:</b> HOLD-TIGHT-TO-SOREKT! <br />
                <b>Rasta:</b> CRYPTO-LIGHTS-UP-THE-NIGHT!
                <br />
                <b>Slim:</b> SOREKT-FAM-STRAIGHT-UP!
                <br /> <b>Rasta:</b> CRYPTO-WARRIORS-PEACE-OUT!
              </p>
              <p className='bulletin_story_r1_creator'>-MiranDuh!-</p>
            </div>
          </div>
          <div className='bulletin_story_row'>
            <img
              style={{ width: '54px', height: '54px' }}
              src={bulletin_mask}
              alt='bulletin_mask'
            />
            <div className='bulletin_story_row_text'>
              <p>
                The story of "rekt" as an internet slang term is one of
                evolution and adaptation. It is believed to have originated in
                gaming communities, specifically in the first person shooter
                communities, as a shorthand way of describing a devastating
                defeat or humiliation. The term quickly spread to other online
                communities, such as forums, social media, and chat rooms, where
                it was used in a similar way to describe a wide range of
                devastating events or setbacks. As the term gained popularity,
                it began to take on new meanings and connotations. Today, "rekt"
                is often used as a playful or exaggerated way to describe a
                minor setback or inconvenience, as well as a more serious and
                dramatic way to describe a major defeat or failure.
              </p>
              <p className='bulletin_story_r1_creator'>- SoRen-</p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className='bulletin'>
      <NavBar />
      <div className='bulletin_head'>
        <img src={bulletin_head} alt='bulletin_head' />
      </div>
      <div className='bulletin_content'>
        <div className='bulletin_btns'>
          <RoundButton
            pos='relative'
            btnh='82.29px'
            btnw='86px'
            grbtnh='82.29px'
            brbtnw='86px'
            name='bulletin_story'
            src={story}
            srcgr={story_dark}
            onClick={() => setSubPage('story')}
          />
          <RoundButton
            pos='relative'
            btnh='82.29px'
            btnw='86px'
            grbtnh='82.29px'
            brbtnw='86px'
            name='bulletin_meme'
            src={meme}
            srcgr={meme_dark_ai}
            onClick={() => {
              setSubPage('meme');
            }}
          />
          <RoundButton
            pos='relative'
            btnh='82.29px'
            btnw='86px'
            grbtnh='82.29px'
            brbtnw='86px'
            name='bulletin_nft'
            src={bulletin_nft}
            srcgr={bulletin_nft_dark}
            onClick={() => {
              setSubPage('nft');
            }}
          />
        </div>
        {child}
      </div>
    </div>
  );
};

export default BulletinPage;
