import { Route, Routes } from 'react-router-dom';
import './App.css';
import HomePage from './HomePage';
import AboutPage from './AboutPage';
import Roadmap from './Roadmap';
import Charity from './Charity';
import Rulebook from './Rulebook';
import Club from './Club';
import BuyToken from './BuyToken';
import TreasureHunt from './TreasureHunt';
import BulletinPage from './BulletinPage';
import TokenPage from './TokenPage';
import BuyNftMiddle from './BuyNftMiddle';
import Inspression from './Inspression';
import ComingSoon from './ComingSoon';
import HowToUse from './HowToUse';
import AiEngineFree from './AiEngineFree';
function App() {
  return (
    <main>
      <Routes>
        <Route path='/' element={<HomePage />} />
        <Route path='/about' element={<AboutPage />} />
        <Route path='/roadmap' element={<Roadmap />} />
        <Route path='/charity' element={<Charity />} />
        <Route path='/rulebook' element={<Rulebook />} />
        <Route path='/club' element={<Club />} />
        <Route path='/buy-token' element={<BuyToken />} />
        <Route path='/treasure-hunt' element={<TreasureHunt />} />
        <Route path='/bulletin' element={<BulletinPage />} />
        <Route path='/token' element={<TokenPage />} />
        <Route path='/buy-nft-offers' element={<BuyNftMiddle />} />
        <Route path='/inspression' element={<Inspression />} />
        <Route path='/coming-soon' element={<ComingSoon />} />
        <Route path='/how-to-use' element={<HowToUse />} />
        <Route path='/ai-engine-free-version' element={<AiEngineFree />} />
      </Routes>
    </main>
  );
}

export default App;
