import React from 'react';
import { NavLink } from 'react-router-dom';
import './Styles/SpinToken.css';

const SpinTokens = (props) => {
  function makeDark() {
    document.getElementById(props.name_dr).style.opacity = '1';
    document.getElementById(props.name).style.opacity = '0';
  }
  function makeLight() {
    document.getElementById(props.name_dr).style.opacity = '0';
    document.getElementById(props.name).style.opacity = '1';
  }

  return (
    <NavLink to={props.link} onMouseOver={makeDark} onMouseOut={makeLight}>
      <img
        style={{
          opacity: '1',
          position: 'absolute',
          top: props.tp,
          bottom: props.bt,
          left: props.le,
          right: props.ri,
          height: props.ht,
          margin: 'auto',
          transform: props.tranform,
        }}
        src={props.src}
        alt={props.src}
        id={props.name}
      />
      <img
        style={{
          opacity: '0',
          position: 'absolute',
          top: props.tp,
          bottom: props.bt,
          left: props.le,
          right: props.ri,
          height: props.ht_dr,
          margin: 'auto',
          transform: props.tranform,
        }}
        src={props.src_dr}
        alt={props.src}
        id={props.name_dr}
      />
    </NavLink>
  );
};

export default SpinTokens;
