import React from 'react';
import './Styles/BuyToken.css';
import NavBar from './NavBar';
import Footer from './Footer';
import {
  back_2_for_yt_1,
  point2_buy_token,
  point3_buy_token,
  start_buy_token,
} from './Assets/Images/Index';
import YouTube from 'react-youtube';

var getYouTubeID = require('get-youtube-id');

const BuyToken = () => {
  const url = getYouTubeID('https://youtu.be/tt0xR3J997I');
  const opts = {
    height: '68%',
    width: '39%',
    playerVars: {
      autoplay: 1,
    },
  };

  return (
    <div className='buy-token'>
      <NavBar />

      <div className='buy-token_start'>
        <img src={start_buy_token} alt='group' className='text-center'></img>
      </div>
      <div className='buy-token_center'>
        <div className='buy-token_cex-dex'>
          <div className='buy-token_dex'>
            <a
              className='buy-token_dex_a'
              rel='noreferrer'
              target='_blank'
              href='https://dex.indexx.ai/'
            >
              https://dex.indexx.ai/
            </a>
          </div>
          <div className='buy-token_cex'>
            <a
              className='buy-token_dex_a'
              rel='noreferrer'
              target='_blank'
              href='https://cex.indexx.ai/'
            >
              https://cex.indexx.ai/
            </a>
          </div>
        </div>
        <br />
        <br />
        <br />
        <br />
      </div>
      <div className='buy-token_point-2'>
        <img
          className='text-center'
          style={{ textAlign: 'center' }}
          src={point2_buy_token}
          alt='point_2'
        />
      </div>
      <div className='buy-token__video'>
        <img
          class='buy-token__video__img'
          src={back_2_for_yt_1}
          alt='back_2_for_yt_1'
        />
        <YouTube videoId={url} opts={opts} />
      </div>
      <div className='buy-token_point-3'>
        <img src={point3_buy_token} alt='point3_buy_token' />
      </div>
      <Footer />
    </div>
  );
};

export default BuyToken;
