import React from 'react';
import {
  coming_soon_footer,
  head_coming_soon,
  head_coming_soon_dark,
  home,
  home_dark,
} from './Assets/Images/Index';
import SpinTokens from './SpinTokens';

const ComingSoon = () => {
  return (
    <div
      className='font_oklahoma'
      style={{
        position: 'absolute',
        minHeight: '100%',
        minWidth: '100%',
        fontSize: '70px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      Coming Soon
      <SpinTokens
        tp='0'
        bt='290px'
        le='0'
        ri='0px'
        ht='180.77px'
        ht_dr='180.77px'
        src={head_coming_soon}
        src_dr={head_coming_soon_dark}
        name='head'
        name_dr='head_dr'
        link='/'
      />
      <SpinTokens
        tp='0px'
        bt='730px'
        le='0'
        ri='1600px'
        ht='82.29px'
        ht_dr='82.29px'
        src={home}
        src_dr={home_dark}
        name='home'
        name_dr='home_dr'
        link='/'
      />
      <img
        style={{ position: 'fixed', bottom: '0px', width: '100%' }}
        src={coming_soon_footer}
        alt='coming_soon_footer'
      />
    </div>
  );
};

export default ComingSoon;
