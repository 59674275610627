import React from 'react';
import './Styles/Team.css';
import NavBar from './NavBar';


import THuntt from './Assets/Images/AboutPage/ftreasure.svg';
import Footer from './Footer';

const TreasureHunt = () => {
  return (
    <div className='about-page'>
      <NavBar />
      <br />
      <br />
      <br />
      <p className='text-center'>
        <img src={THuntt} alt='group' className='text-center'></img>
      </p>

      <Footer />
    </div>
  );
};

export default TreasureHunt;
