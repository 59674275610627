import React from 'react';
import {
  bgd_left_token,
  bgd_right_token,
  content_token,
} from './Assets/Images/Index';
import Footer from './Footer';
import NavBar from './NavBar';
import './Styles/TokenPage.css';

const TokenPage = () => {
  return (
    <div className='token-page'>
      <NavBar />
      <div className='token-page_bgd'>
        <div className='token-page_bgd_l'>
          <img src={bgd_left_token} alt='bgd_left_token' />
        </div>
        <div className='token-page_bgd_r'>
          <img src={bgd_right_token} alt='bgd_right_token' />
        </div>
        <div className='token-page_content'>
          <img src={content_token} alt='content_token' />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default TokenPage;
