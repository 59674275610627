import React from 'react';
import './Styles/PopUp.css';

const PopUp = (props) => {
  let popPos, popAlign;
  if (props.position === undefined) {
    popPos = 'fixed';
  } else {
    popPos = props.position;
  }

  if (props.popAlign === undefined) {
    popAlign = 'center';
  } else {
    popAlign = props.popAlign;
  }

  return props.trigger ? (
    <div
      className='pop-up'
      onClick={props.onClickOutsidePopUp}
      style={{
        position: popPos,
        top: '0',
        left: '0',
        right: '0',
        bottom: '0',
        margin: 'auto',
        minHeight: '100%',
        minWidth: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: popAlign,
        zIndex: '99',
        backdropFilter: props.backDrop,
      }}
    >
      <div
        className='pop-up_inner'
        style={{
          height: props.ht,
          width: props.wd,
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        {props.children}
      </div>
    </div>
  ) : (
    ''
  );
};

export default PopUp;
