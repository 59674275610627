import React from 'react';
import { btn, btn_grey } from './Assets/Images/Index';

const Button = (props) => {
  let idName = props.name;
  let txtColor = props.txtColor;

  if (typeof txtColor === 'undefined') {
    txtColor = '#474747';
  }
  if (typeof idName === 'undefined') {
    idName = 'img';
  }

  function makeDark(e) {
    document.getElementById(idName).style.opacity = '1';
  }
  function makeLight(e) {
    document.getElementById(idName).style.opacity = '0';
  }
  return (
    <div>
      <button
        className='Button__hover'
        style={{
          position: 'relative',
          height: props.btnh,
          width: props.btnw,
          border: 'none',
          backgroundColor: 'rgba(52, 52, 52, 0.0)',
          textAlign: 'center',
        }}
        onMouseOver={makeDark}
        onMouseOut={makeLight}
        onClick={props.onClick}
      >
        <img
          src={btn}
          alt='btn'
          style={{
            position: 'absolute',
            top: '0',
            bottom: '0',
            left: '0',
            right: '0',
            height: props.btnh,
            width: props.btnw,
          }}
        />
        <img
          id={idName}
          src={btn_grey}
          alt='btn_grey'
          style={{
            position: 'absolute',
            top: '0',
            bottom: '0',
            left: '0',
            right: '0',
            opacity: '0',
            height: props.grbtnh,
            width: props.grbtnw,
          }}
        />

        <p
          style={{
            position: 'absolute',
            top: props.txtt,
            bottom: props.txtb,
            left: props.txtl,
            right: props.txtr,
            height: 'fit-content',
            width: 'max-content',
            fontWeight: props.txtwt,
            fontSize: props.txtsz,
            lineHeight: props.txtlh,
            textShadow: props.txtsh,
            fontFamily: props.txtf,
            color: { txtColor },
          }}
        >
          {props.txt}
        </p>
      </button>
    </div>
  );
};

export default Button;
