import React from 'react';

const RoundButton = (props) => {
  let idName = props.name;

  function makeDark(e) {
    document.getElementById(idName).style.opacity = '1';
  }
  function makeLight(e) {
    document.getElementById(idName).style.opacity = '0';
  }

  if (typeof idName === 'undefined') {
    idName = 'img';
  }
  return (
    <div>
      <button
        style={{
          position: props.pos,
          top: props.top,
          bottom: props.btm,
          left: props.le,
          right: props.ri,
          height: props.btnh,
          width: props.btnw,
          border: 'none',
          margin: props.mr,
          backgroundColor: 'rgba(52, 52, 52, 0.0)',
          textAlign: 'center',
        }}
        onMouseOver={makeDark}
        onMouseOut={makeLight}
        onClick={props.onClick}
      >
        <img
          src={props.src}
          alt='btn'
          style={{
            position: 'absolute',
            top: '0',
            bottom: '0',
            left: '0',
            right: '0',
            height: props.btnh,
            width: props.btnw,
          }}
        />
        <img
          id={idName}
          src={props.srcgr}
          alt='btn_grey'
          style={{
            position: 'absolute',
            top: '0',
            bottom: '0',
            left: '0',
            right: '0',
            opacity: '0',
            height: props.grbtnh,
            width: props.grbtnw,
          }}
        />
      </button>
    </div>
  );
};

export default RoundButton;
