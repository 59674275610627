import React from 'react';
import './Styles/AiNft.css';

const AiNft = (props) => {
  return (
    <div className='ai-nft'>
      <img className='ai-nft_image' src={props.src} alt={props.src} />
      <p className='ai-nft_number'>{props.name}</p>
      {/* <p className='ai-nft_desc'>{props.desc1}</p>
      <p className='ai-nft_desc'>{props.desc2}</p> */}
      <a href={props?.desc1} target='_blank' rel="noreferrer">
        <p className='ai-nft_desc'>Opensea</p>
      </a>
      <a href={props?.desc2} target='_blank' rel="noreferrer">
        <p className='ai-nft_desc'>Etherscan</p>
      </a>
    </div>
  );
};

export default AiNft;
