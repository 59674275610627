import React from 'react';
import './Styles/ImageCreators.css';

const ImageCreators = ({ imageData }) => {
  return (
    <div className='bulletin_image'>
      <div className='bulletin_image_img'>
        <img src={imageData.src} alt={imageData.src}></img>
      </div>
      <div className='bulletin_image_txt'>
        <p>Creator : {imageData.creator}</p>
      </div>
    </div>
  );
};

export default ImageCreators;
