import React from 'react';
import './Styles/Team.css';
import NavBar from './NavBar';

import roadmap from './Assets/Images/AboutPage/realmapp.svg';

import Footer from './Footer';

const Roadmap = () => {
  return (
    <div className='about-page'>
      <NavBar />

      <p
        className='text-center'
        style={{ textAlign: 'center', marginLeft: '-11%' }}
      >
        <img className='text-center' src={roadmap} alt='roadmap'></img>
      </p>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <Footer />
    </div>
  );
};

export default Roadmap;
